.mapview {
  display: -webkit-box;
  filter: blur(1px);
  position: relative; /* Added to contain absolutely positioned children */
}

@media only screen and (max-width: 768px) {
  .mapview {
    filter: none;
  }
}
